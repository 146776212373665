import { OnInit, Component, AfterViewInit, EventEmitter, Output, ElementRef, Directive, Input, ViewChild} from '@angular/core';
import { shop_category, sidedown, display_inline_block, airdrop_sidedown} from '../../app.animations';
@Component({
    selector: 'airdrop-info',
    animations: [shop_category, display_inline_block, sidedown, airdrop_sidedown],
    templateUrl: 'airdrop-info.component.html',
    styleUrls: [ 'airdrop-info.component.styl' ]
})
export class AirdropInfoComponent implements OnInit, AfterViewInit {
    @Input('airdrop_cards_per_pack') airdrop_cards_per_pack: any;
    @Input('alphaAirdropCard') alphaAirdropCard: any;
    @Input('guaranteedDrops') guaranteedDrops: any;
    @Input('packs_purchased') packs_purchased: any;
    @Input('selected_currency') selected_currency: any;
    @Input('selected_value') selected_value: any;
    @Input('milestone') milestone: any;
    @Input('category') category: any;
    @Input('cards') cards: any;
    @Input('price') price: any;
    @Input('name') name: any;
    @Input('item') item: any;
    @Input('show') show: any;
    @Output('hide') hide = new EventEmitter();
    crate = new PreSale(1);
    panel_hidden = false;
    close_airdrop() {
    	this.show = false;
    	this.hide.emit(1);
    }
    create_quantity() {
      
    }
    ngOnInit() {
      
    }
    ngAfterViewInit() {
    }
}
export class PreSale {
  constructor(
    public qty?: number
  ) {}
}