import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up, pointer } from '../../app.animations';
@Component({
  selector: 'burn-card',
  animations: [fade, flip_up, pointer],
  templateUrl: './burn-card.component.html',
  styleUrls: [
    './burn-card.component.less'
  ]
})
export class BurnCardComponent implements OnInit {
  @Input('show') show = false;
  @Input('label') top_label = '';
  @Input('mode') modal_mode = '';
  @Input('title') title = 'Burn Card';
  @Input('burn_gems') burn_gems = [];
  @Input('cards') cards = [];
  @Input('card') card = [];
  @Input('type') type = '';
  @Input('burn_value') burn_value = '0';
  @Input('burnType') method = 'FORGE';
  @Output('done') done = new EventEmitter();
  label = '';
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
  }
  ngOnInit() {
    // this.label = this.top_label.replace('%s', this.burn_value);
  }
  done_button(status) {
    this.done.emit({status:status, type:this.method});
  }
  close() {
    this.show = false
    this.done.emit(0);
  }
  change_method(type) {
    if (type !== this.method) {
      this.method = type;
      if (type === 'Electrum') this.burn_value = (parseInt(this.burn_value) * 50).toString(); // regular = *10, *50 during mini burn event
      else this.burn_value = (parseInt(this.burn_value) / 50).toString(); // regular = *10, *50 during mini burn event
    }
  }
}