import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({ 
    templateUrl: 'slcard.component.html', 
    selector: 'slcard-selector',
    styleUrls: [  'slcard.component.styl', '../stats-icons/stats.component.styl' ]
})
export class SplinterlandsCardComponent implements OnInit {

    @Input('card') card: any;
    @Input('small') small = true;
    @Input('blur') blur = 'yes';
    @Input('fav') fav = true;
    @Input('showEnergy') showEnergy = false;
    @Output('select') select = new EventEmitter();
    @Output('toggle') toggle = new EventEmitter();

    constructor() { }

    ngOnInit() {
        //if (this.card.uid.indexOf(' hero') > -1) console.log(this.card);
        if (this.card.uid && this.card.uid.indexOf(' hero') > -1) {
            //console.log(this.card);
            let temp = this.card.uid.split(' ');
            if (temp && ['Ranger','Warrior','Wizard'].includes(temp[0])) this.card.currentClass = temp[0];
            this.card.imgURL = '/assets/heroes/' + this.card.currentClass.toLowerCase() + '_sketch.png';
        } else if (this.card && this.card.color === 'ALL') {
            this.card.imgURL = '/assets/heroes/' + this.card.uid.split(' ')[0].toLowerCase() + '_sketch.png';
        }
    }
    
    selectCard() {
        this.select.emit(1);
    }

    toggleFavorite() {
        this.toggle.emit(1);
    }
}
