<div class="category_container" [@airdrop_sidedown]="show">
	<div class="shop_items airdrop_info" [@shop_category]="!panel_hidden" [attr.id]="'airdrop_'+name">
		<i class="close fa-solid fa-xmark" (click)="close_airdrop()"></i>
		<div class="shop_item">
			<h1 class="title">
				<span>Airdrop {{name}}</span>
			</h1>
			<div class="airdrop_zone">
				<div class="airdrop_container">
					<div>
						<div class="sfcard_selector">
							<div class="sf_card">
								<sfcard-selector
									[sfcard]="alphaAirdropCard"
									[sfcards]="cards"
									[stackView]="false"
									[smaller]="false">
								</sfcard-selector>
							</div>
						</div>
					<!--
						<div class="airdrop_purchase">
							<div *ngIf="alphaAirdropCard" class="border_long_left"></div>
							<div *ngIf="alphaAirdropCard" class="border_long_right"></div>
							<div *ngIf="alphaAirdropCard" class="border_long_top"></div>
							<div *ngIf="alphaAirdropCard" class="border_long_bottom"></div>
							<div *ngIf="!alphaAirdropCard" class="border_left_l"></div>
							<div *ngIf="!alphaAirdropCard" class="border_right_l"></div>
							<h1 class="purchase_title">Purchase</h1>
							<div *ngIf="alphaAirdropCard" class="available_now">Pre-Order Now</div>
							<div class="price_subl" *ngIf="price">{{price*crate.qty|number}} FORGE</div>
							<h1 class="name_title">{{name}}</h1>
							<div class="purchase_container">
							    <input class="buy_pack_quantity" type="number" (change)="create_quantity()" (keyup)="create_quantity()" min="1" step="1" [attr.value]="1" [(ngModel)]="crate.qty" />
	      						<div class="button gold purchase_button"><span>Purchase</span></div>
							</div>
						</div>
					-->

						<div *ngIf="alphaAirdropCard" [attr.class]="!alphaAirdropCard?'sf_descriptor no_cards':'sf_descriptor'">
							<div *ngIf="!alphaAirdropCard" class="border_left_l first"></div>
							<h1>
								<span class="white">Airdrop</span>
								<span class="yellow">{{alphaAirdropCard.name}}</span>
							</h1>
							<h2 class="text_left">
								<span class="red">{{alphaAirdropCard.slot}} Equipment</span>
							</h2>
							<h2 class="text_right">
								<span class="red">Adds {{alphaAirdropCard.stat}}</span>
							</h2>
							<p class="left first_airdrop_info">
								The first airdrop will happen after <b>200k</b> packs have sold.
							</p>
							<p class="left gaurentee_packs">
								Players are guaranteed <b>1 Airdrop</b> card for every {{airdrop_cards_per_pack}} packs purchased.
							</p>
							<p class="left gaurenteed_packs">
								You are guaranteed {{guaranteedDrops==0?'no':guaranteedDrops}} {{alphaAirdropCard.name}} based on {{packs_purchased==0?'no':'your '+packs_purchased}} packs purchased.
								<br />
								<span *ngIf="packs_purchased==0">Purchase an {{name}} to get a gaurenteed Airdrop.</span>
							</p>
							<div class="clear"></div>
							<!-- <ul class="airdrop_desc">
								<li>Next airdrop card is <b>{{name}}</b> and will be airdropped after {{milestone}} packs have been sold.</li>
								<li>Players are guaranteed <b>1 Airdrop</b> card for every {{airdrop_cards_per_pack}} packs purchased.</li>
								<li>You are guaranteed {{guaranteedDrops}} {{name}} based on your {{packs_purchased}} packs purchased.</li>
							</ul>  -->
						<!--
							<div class="tab_bar">
								<button [disabled]="running" class="tablinks">
									Pick New Boss
								</button>
							</div> 
						-->
						<div class="clear"></div>
					</div>

					<div class="clear"></div>
				</div>
			</div>
		</div>
	</div>
</div>