import { NgModule } from '@angular/core';
import { CommonModule,DecimalPipe } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from "ngx-accordion";
import { SkyhookDndModule } from "@angular-skyhook/core";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TimeagoModule } from 'ngx-timeago';
import { DragulaModule } from 'ng2-dragula';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ShopComponent } from './shop/shop.component';
import { AirdropInfoComponent } from './shop/airdrop-info/airdrop-info.component';
import { BonusItemsComponent } from './shop/bonus-items/bonus-items.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ProfileComponent } from './profile/profile.component';
import { HttpClientModule } from '@angular/common/http';
import { SLCardsComponent } from './slcards/slcards.component';
import { SLCardComponent } from './slcard/slcard.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { ReplayComponent } from './replay/replay.component';
import { CardsComponent } from './cards/cards.component';
import { OpenPacksComponent } from './openpacks/openpacks.component';
//import { HeroesComponent } from './heroes/heroes.component';
import { HeroComponent } from './hero/hero.component';
import { SFCardComponent } from './cards/sfcard/sfcard.component';
import { SelectedCardComponent } from './cards/selectedcard/selectedcard.component';
import { StatsComponent } from './slcards/stats-icons/stats.component';
import { BarsComponent } from './slcards/stats-bars/bars.component';
import { StatsAbilitiesComponent } from './slcards/stats-abilities/abilities.component';
import { AbilitiesWindowComponent } from './slcards/stats-abilities/abilities-window/abilities-window.component';
import { MaxLevelsComponent } from './slcards/boss-max-levels/max-levels.component'
import { LoginComponent } from './_modals/login/login.component';
import { HiveWelcomeComponent } from './_modals/hive-welcome/hive-welcome.component';
import { NgFireworksModule } from '../../custom_modules/fireworks/projects/ng-fireworks/src/lib/ng-fireworks.module';
import { LoadingComponent } from './_modals/loading/loading.component';
import { PeriodPipe } from './_pipes/period.pipe';
import { UIDPipe } from './_pipes/uid.pipe';
import { SortPipe } from './_pipes/sort.pipe';
import { URLEncoderPipe } from './_pipes/url_encoder.pipe';
import { NumberLoopPipe } from './_pipes/number_loop.pipe';
import { TypeofPipe } from './_pipes/typeof.pipe';
import { TypePipe } from './_pipes/type.pipe';
import { IDPipe } from './_pipes/id.pipe';
import { CapitalizePipe } from './_pipes/capitalize.pipe';
import { CamelcasePipe } from './_pipes/camelcase.pipe';
import { RarityToStringPipe } from './_pipes/stringify_rarity.pipe';
import { TXTitlePipe } from './_pipes/tx_title.pipe';
import { NamePipe } from './_pipes/name.pipe';
import { FoilPipe } from './_pipes/foil.pipe';
import { NameIncludesPipe } from './_pipes/name_includes.pipe';
import { LevelPipe } from './_pipes/level.pipe';
import { SelectedPipe } from './_pipes/selected.pipe';
import { FilteredPipe } from './_pipes/filtered.pipe';
import { DmgTypePipe } from './_pipes/dmgType.pipe';
import { AbilityPipe } from './_pipes/ability.pipe';
import { AbilityNamePipe } from './_pipes/ability_name.pipe';
import { EquippedPipe } from './_pipes/equipped.pipe';
import { FavoritesPipe } from './_pipes/favorites.pipe';
import { RarityPipe } from './_pipes/rarity.pipe';
import { SocketsPipe } from './_pipes/sockets.pipe';
import { SlotPipe } from './_pipes/slot.pipe';
import { RemovePlusPipe } from './_pipes/remove_plus.pipe';
import { RemoveDashPipe } from './_pipes/remove_dash.pipe';
import { AgilitiesPlacementPipe } from './_pipes/agilities_placement.pipe';
import { AbilitiesAvailablePipe } from './_pipes/abilities_available.pipe';
import { AbilityDescriptionPipe } from './_pipes/abilities_description.pipe';
import { AudioPlacementPipe } from './_pipes/audio_placement.pipe';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LogComponent } from './replay/log/log.component';
import { FWShowComponent } from './replay/fw-show/fw-show.component';
import { CardFiltersComponent } from './slcards/card-filters/card-filters.component';
import { NotificationComponent } from './notification/notification.component';
import { StatsLeagueComponent } from './slcards/stats-league/stats-league.component';
import { BurnCardComponent } from './_modals/burn-card/burn-card.component';
import { OpenPackComponent } from './_modals/open-pack/open-pack.component';
import { StaminaElixirsComponent } from './_modals/stamina-elixirs/stamina-elixirs.component';
import { RewardsComponent } from './_modals/rewards/rewards.component';
import { TeamResultComponent } from './_modals/team-result/team-result.component';
import { ForgiumComponent } from './_modals/forgium/forgium.component';
import { TransferItemComponent } from './_modals/transfer-item/transfer-item.component';
import { WelcomeComponent } from './_modals/welcome/welcome.component';
import { HeadingComponent } from './header/heading/heading.component';
import { DonateComponent } from './_modals/donate/donate.component';
import { SuccessComponent } from './_modals/success/success.component'
import { ProductModalComponent } from './_modals/product/product-modal.component';
import { NoticeComponent } from './_modals/notice/notice.component';
import { SLItemImage } from './shop/item-image/item-image.component';
import { TransferCardComponent } from './_modals/transfer-card/transfer-card.component';
import { SelectCardComponent } from './_modals/select-card/select-card.component';
//import { AnimationTestComponent } from './replay/animation-test/animation-test.component';
import { AirdropsComponent } from './airdrops/airdrops.component';
import { SplinterlandsCardComponent } from './slcards/splinterland-card/slcard.component';
import { IntroductionComponent } from './_modals/introduction/introduction.component';
import { DailyComponent } from './_modals/daily/daily.component';
import { SurvivalComponent } from './survival/survival.component';
import { MineRunRulesComponent } from './survival/mineRunRules/mineRunRules.component';
import { RezModalComponent } from './survival/rezModal/rezModal.component';
import { FightSummaryComponent } from './survival/fightSummary/fightSummary.component';
import { LeadersComponent } from './survival/leaders/leaders.component';
import { EquipComponent } from './hero/equip/equip.component';
import { SkillsComponent } from './hero/skills/skills.component';
@NgModule({
    declarations: [
        AppComponent,
        ReplayComponent,
        HomeComponent,
        FaqsComponent,
        ShopComponent,
        SLCardsComponent,
        SLCardComponent,
        ProfileComponent,
        LeaderboardComponent,
        CardsComponent,
        OpenPacksComponent,
        //HeroesComponent,
        HeroComponent,
        SFCardComponent,
        SelectedCardComponent,
        StatsComponent,
        BarsComponent,
        StatsAbilitiesComponent,
        TransferCardComponent,
        MaxLevelsComponent,
        LoadingComponent,
        FooterComponent,
        HeaderComponent,
        LogComponent,
        FWShowComponent,
        CardFiltersComponent,
        NotificationComponent,
        AgilitiesPlacementPipe,
        AbilitiesWindowComponent,
        AbilitiesAvailablePipe,
        AbilityDescriptionPipe,
        StatsLeagueComponent,
        OpenPackComponent,
        BurnCardComponent,
        EquipComponent,
        SelectedPipe,
        FilteredPipe,
        DmgTypePipe,
        AbilityPipe,
        AbilityNamePipe,
        EquippedPipe,
        FavoritesPipe,
        RarityPipe,
        SocketsPipe,
        SlotPipe,
        AudioPlacementPipe,
        RemovePlusPipe,
        RemoveDashPipe,
        TypeofPipe,
        NameIncludesPipe,
        NamePipe,
        FoilPipe,
        TypePipe,
        TXTitlePipe,
        CapitalizePipe,
        RarityToStringPipe,
        CamelcasePipe,
        IDPipe,
        LevelPipe,
        SortPipe,
        UIDPipe,
        PeriodPipe,
        NumberLoopPipe,
        URLEncoderPipe,
        LoginComponent,
        BonusItemsComponent,
        HiveWelcomeComponent,
        AirdropInfoComponent,
        RewardsComponent,
        StaminaElixirsComponent,
        TeamResultComponent,
        ForgiumComponent,
        TransferItemComponent,
        WelcomeComponent,
        NoticeComponent,
        SLItemImage,
        SelectCardComponent,
        HeadingComponent,
        DonateComponent,
        SuccessComponent,
        ProductModalComponent,
        //AnimationTestComponent,
        AirdropsComponent,
        SplinterlandsCardComponent,
        IntroductionComponent,
        DailyComponent,
        SurvivalComponent,
        MineRunRulesComponent,
        RezModalComponent,
        FightSummaryComponent,
        LeadersComponent,
        SkillsComponent
    ],
    providers: [
        DecimalPipe
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        NgFireworksModule,
        NgxSliderModule,
        AccordionModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DragulaModule.forRoot(),
        SkyhookDndModule.forRoot({ backend: HTML5Backend }),
        TimeagoModule.forRoot()
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }