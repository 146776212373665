<introduction [show]="introPopup"></introduction>
<daily-modal 
	*ngIf="dailyPopup"
	[show]="dailyPopup"
	[user]="currentUser"
	(done)="dailyPopup = false">
</daily-modal>
<notice-modal
	[show]="show_nav_confirmation"
	[title]="show_nav_confirmation_title"
	[label]="show_nav_confirmation_label"
	[mode]="'confirmation'"
></notice-modal>
<section id="header1">
	<div class="bg_layer_1"></div>
	<div class="bg_layer_2"></div>
	<div class="bottom_border"></div>
  <div class="inner">
		<div class="logowrap">
			<a class="hgLogo" id="logo" (click)="navigate('')" tabindex="1">
				<img src="/assets/splinter-forge-logo-finalx400-min.png" loading="lazy">
			</a>
		</div>
		<div class="topnav" id="navbar">
			<div id="navbar-left" *ngIf="currentUser" [attr.class]="displayNav?'open':''">
				<div class="top_border"></div>
				<div class="bottom_border"></div>
				<a tabindex="2" *ngIf="pre_sale" [attr.class]="helper.active=='presale'?'active':''" id="presaleSelector" (click)="navigate('presale')">
					<div class="imagewrapper"><img src="/assets/menu/menu-shop.png" loading="lazy"></div>
					<div class="titleoverlay">Pre-Sale</div>
				</a>
				<a tabindex="3" [attr.class]="helper.active=='shop'?'active':''" id="shopSelector" (click)="navigate('shop')">
				  <div class="imagewrapper"><img src="/assets/menu/menu-shop.png" loading="lazy"></div>
				  <div class="titleoverlay">Shop</div>
				</a>
				<a tabindex="4" [attr.class]="helper.active=='openpacks'?'active':''" id="openpacksSelector" (click)="navigate('openpacks')">
				  <div class="imagewrapper"><img src="/assets/menu/menu-hammer-anvil.png" loading="lazy"></div>
				  <div class="titleoverlay">Forge</div>
				</a>
				<a tabindex="5" [attr.class]="helper.active=='cards'?'active':''" id="cardsSelector" (click)="navigate('cards')">
				  <div class="imagewrapper"><img src="/assets/menu/menu-profile.png" loading="lazy"></div>
				  <div class="titleoverlay">Cards</div>
				</a>
				<a tabindex="6" [attr.class]="helper.active=='heroes'?'active':''" id="heroesSelector" (click)="navigate('heroes')">
					<div class="imagewrapper"><img src="/assets/heroes/{{currentUser.currentClass|lowercase}}_sketch.png" loading="lazy"></div>
					<div class="titleoverlay">Heroes</div>
				</a>
				<a tabindex="7" [attr.class]="helper.active=='slcards'?'active':''" id="slcardsSelector" (click)="navigate('slcards')">
				  <div class="imagewrapper"><img src="/assets/menu/menu-boss.png" loading="lazy"></div>
				  <div class="titleoverlay">Battle</div>
				</a>
				<a tabindex="8" [attr.class]="helper.active=='survival'?'active':''" id="survivalSelector" (click)="navigate('survival')">
					<div class="imagewrapper"><img src="/assets/menu/menu-mine.png" loading="lazy"></div>
					<div class="titleoverlay">Survival</div>
				</a>
				<a tabindex="9" [attr.class]="helper.active=='quests'?'active':''" id="questsSelector" (click)="navigate('quests')">
					<div class="imagewrapper"><img src="/assets/menu/menu-quests.png" loading="lazy"></div>
					<div class="titleoverlay">Quests</div>
				</a>
			</div>
			<div id="navbar-right" *ngIf="currentUser">
				<a tabindex="19" title="Global reward modifier is determinued using current market value of FORGE.  Refer to user guide for more info.">
					<div class="modNum">{{globalMod}}%</div>
				  <span class="titleoverlay">Global Modifier</span>
				</a>
				<a tabindex="10" [attr.class]="currentUser?'info_button':'info_button loggedout'" 
					 (click)="requested_button()" (keyup.enter)="requested_button()">
					<i class="fa-solid fa-info"></i>
				  <span class="titleoverlay">Information & Help</span>
				</a>
				<div class="coinwrapper">
				  <a tabindex="11" class="coin crystals" title="Forgium" (click)="moveSC()">
				    <img src="https://splinterforge.s3.us-east-2.amazonaws.com/tokens/forgium.png" width="40" height="40" loading="lazy">
				    <div class="value">
				    	<span>{{currentUser.sc.balance|number:'1.0-0'}}</span>
				    	<div class="progress_bar">
				    		<!-- <div class="background"></div> -->
				    	</div>
				    </div>
				    <div class="titleoverlay">Forgium</div>
				  </a>
				  <a tabindex="12" class="coin stamina" title="Mana regens 1 per minute. Click to use potions!" (click)="useStamina()" (keyup.enter)="useStamina()" (keyup.space)="user_click()">
				    <img src="/assets/menu/menu-stamina.png" width="40" height="40" loading="lazy">
				    <div class="value">
				    	<span>
				    		{{currentUser.stamina.current+stamina_regen}} / {{currentUser.stamina.max}}
				    	</span>
				    	<div class="progress_bar" 
				    		[attr.style]="'width:'+(((currentUser.stamina.current+stamina_regen)/currentUser.stamina.max)*100)+'%'">
				    		<div class="background"></div>
				    	</div>
				    </div>
				    <div class="titleoverlay">Stamina</div>
				  </a>
				</div>
				<div [attr.class]="helper.active=='profile'?'active profile_wrapper':'profile_wrapper'">
					<a tabindex="13" (click)="user_click()" (keyup.enter)="user_click()" (keyup.space)="user_click()" class="profile">
					  <div class="imagewrapper">
					    <img src="/assets/menu/menu-profile.png" loading="lazy">
					  </div>
					  <div *ngIf="notifications > 0" class="titleoverlay">
					  	{{notifications}} {{notifications==1?'Notice':'Notices'}}
					  </div>
					  <div *ngIf="!notifications || notifications == 0" class="titleoverlay">
					  	{{currentUser.username}}
					  </div>
					</a>
				</div>
	    </div>
	    <div id="navbar-right" *ngIf="!currentUser">
			<div [attr.class]="helper.active=='profile'?'active profile_login_wrapper':'profile_login_wrapper'">
				<a tabindex="2" (click)="openLoginModal()" (keyup.enter)="openLoginModal()" (keyup.space)="openLoginModal()" class="profile">
				  <div class="imagewrapper">
				    <img src="/assets/menu/menu-profile.png" loading="lazy">
				  </div>
				  <div class="titleoverlay">Login</div>
				</a>
			</div>
	    </div>
    </div>
    <a *ngIf="currentUser" class="mobile_icon" (click)="respFunc()">
      <div [attr.class]="displayNav?'bars active':'bars'">
	      <div class="one"></div>
	      <div class="two"></div>
	      <div class="three"></div>
    	</div>
    </a>
  </div>
</section>